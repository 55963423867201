<template>
  <div
    class="flex h-[52px] min-h-[52px] w-full items-center justify-between border-b border-lunar bg-white px-5"
  >
    <div class="flex gap-5">
      <button
        v-if="
          route.meta.sidebars &&
          !(
            route.meta.sidebars.includes(SidebarType.USER_CABINET) ||
            route.meta.sidebars.includes(SidebarType.NONE)
          )
        "
        class="flex h-9 w-9 items-center justify-center rounded-[10px] bg-hoar/10"
        @click="model = !model"
      >
        <img alt="" class="img--hoar" src="@/assets/icons/sidebar.svg" />
      </button>
      <app-button
        v-if="
          route.meta.sidebars &&
          (route.meta.sidebars.includes(SidebarType.USER_CABINET) ||
            route.meta.sidebars.includes(SidebarType.NONE))
        "
        size="medium"
        type="half-contained"
        variant="tertiary"
        @click="router.push(`/${Route.KNOWLEDGE_BASE}`)"
      >
        <img
          alt="<"
          class="img--hoar -ml-1 h-[18px] w-[18px] rotate-180"
          src="@/assets/icons/direction/arrow-right-simple.svg"
        />
        <span>Вернуться назад</span>
      </app-button>
      <button>
        <img alt="boostbiz" src="@/assets/logos/boostbiz.png" />
      </button>
    </div>
    <div v-if="route.name !== Route.ORG_STRUCTURE_TEMPLATE" class="flex gap-3">
      <app-select
        v-if="shouldShowProjects"
        v-model="projectsStore.selectedProjectId"
        :options="projectsOptions"
        button-styles="bg-hoar/10 px-1 rounded-[10px]"
        @update:model-value="handleProjectChange"
      >
        <template #button="{ item }">
          <div
            v-if="!Array.isArray(item)"
            class="flex h-9 items-center gap-1.5"
          >
            <div
              :style="{ backgroundColor: item.color }"
              class="flex h-7 w-7 items-center justify-center rounded-[10px]"
            >
              <p class="text-14 font-semibold uppercase text-white">
                {{ item.title[0] }}
              </p>
            </div>
            <p class="mr-1.5 text-13 font-semibold text-hoar">
              {{ item.title }}
            </p>
          </div>
        </template>
        <template #bottom>
          <div
            class="sticky -bottom-1 h-11 w-full border-t border-lunar bg-white p-2"
          >
            <app-button
              class="w-full whitespace-nowrap"
              size="tiny"
              type="texted"
              @click="emit('add-project')"
              >Добавить проект
            </app-button>
          </div>
        </template>
      </app-select>
      <!--      <button-->
      <!--        class="relative flex h-9 w-9 items-center justify-center rounded-[10px] bg-hoar/10"-->
      <!--      >-->
      <!--        <img alt="" class="img&#45;&#45;hoar" src="@/assets/icons/bell.svg" />-->
      <!--        <span-->
      <!--          class="top absolute -right-[7px] -top-[3px] h-4 rounded-full bg-primary px-[5px] text-11 font-semibold text-white"-->
      <!--          >18</span-->
      <!--        >-->
      <!--      </button>-->
      <div
        class="flex h-[36px] items-center gap-2 rounded-[10px] bg-hoar/10 px-4"
      >
        <button @click="balanceDialogStore.isOpen = true">
          <img
            alt=""
            class="img--primary w-[18px]"
            src="@/assets/icons/plus-circle-filled.svg"
          />
        </button>
        <span
          v-if="userStore.user?.owner"
          class="text-13 font-semibold text-base"
          >{{ Number(userStore.user?.balance || 0).toLocaleString() }} ₽</span
        >
      </div>
      <app-popover1 :arrow="true" placement="bottom">
        <template #default>
          <button
            class="flex h-9 items-center justify-center gap-1.5 rounded-[10px] bg-hoar/10 px-1"
          >
            <span
              class="-mt-0.5 flex h-7 w-7 items-center justify-center rounded-[10px] bg-hoar/50"
            >
              <img
                alt=""
                class="img--white mt-2"
                src="@/assets/icons/user/user-main.svg"
              />
            </span>
            <img
              alt=""
              class="img--hoar mr-0.5 h-[14px] w-[14px]"
              src="@/assets/icons/direction/arrow-bottom-simple.svg"
            />
          </button>
        </template>
        <template #content="{ close }">
          <div class="pr-[13px]">
            <div
              class="flex w-[260px] flex-col items-center rounded-[10px] border border-lunar bg-white pt-3 shadow-light"
            >
              <div
                class="relative mb-2 flex h-[52px] w-[52px] justify-center overflow-hidden rounded-full bg-hoar/25"
              >
                <img
                  alt=""
                  class="img--white absolute -bottom-1 h-[44px] w-[41px]"
                  src="@/assets/icons/user/user-main.svg"
                />
              </div>
              <h2 class="text-14 font-semibold text-base">
                {{ userName ? userName : userStore.user?.email }}
              </h2>
              <p class="text-13 font-medium text-hoar">
                <!--                {{ userStore.user. }}-->
                <!--                •-->
                {{ userName ? `${userStore.user?.email}` : "" }}
              </p>
              <div class="mt-3 w-full border-t border-lunar p-[6px]">
                <button
                  class="flex w-full gap-2 rounded-[10px] px-[10px] py-[9px] text-13 font-semibold text-hoar hover:bg-hoar/10 hover:text-base"
                  @click="
                    router.push(
                      userStore.user?.owner
                        ? `/${Route.ADMIN_PROFILE}`
                        : `/${Route.USER_PROFILE}`,
                    )
                  "
                >
                  <img
                    alt=""
                    class="img--hoar h-[18px] w-[18px]"
                    src="@/assets/icons/user/user-circle.svg"
                  />
                  Профиль
                </button>
                <template v-if="userStore.user?.owner">
                  <button
                    class="flex w-full gap-2 rounded-[10px] px-[10px] py-[9px] text-13 font-semibold text-hoar hover:bg-hoar/10 hover:text-base"
                    @click="router.push(`/${Route.PROJECTS}`)"
                  >
                    <img
                      alt=""
                      class="img--hoar h-[18px] w-[18px]"
                      src="@/assets/icons/project.svg"
                    />
                    Управление проектами
                  </button>
                  <button
                    class="flex w-full gap-2 rounded-[10px] px-[10px] py-[9px] text-13 font-semibold text-hoar hover:bg-hoar/10 hover:text-base"
                  >
                    <img
                      alt=""
                      class="img--hoar h-[18px] w-[18px]"
                      src="@/assets/icons/status-up.svg"
                    />
                    Настройка тарифа
                  </button>
                </template>
              </div>
              <div
                v-if="userStore.user?.owner"
                class="w-full border-t border-lunar p-[6px]"
              >
                <button
                  class="flex w-full gap-2 rounded-[10px] px-[10px] py-[9px] text-13 font-semibold text-hoar hover:bg-hoar/10 hover:text-base"
                >
                  <img
                    alt=""
                    class="img--hoar h-[18px] w-[18px]"
                    src="@/assets/icons/message.svg"
                  />
                  Тех. поддержка
                </button>
              </div>
              <div class="flex w-full gap-2 border-t border-lunar p-[6px]">
                <button
                  class="group flex w-full gap-2 rounded-[10px] px-[10px] py-[9px] hover:bg-hoar/10"
                  @click="handleLogOut(close)"
                >
                  <img
                    alt=""
                    class="img--hoar group-hover:img--tomato h-[18px] w-[18px]"
                    src="@/assets/icons/open-door-outlined.svg"
                  />
                  <span
                    class="text-13 font-semibold text-hoar group-hover:text-tomato"
                  >
                    Выйти
                  </span>
                </button>
              </div>
            </div>
          </div>
        </template>
      </app-popover1>
    </div>
    <app-button
      v-else
      size="small"
      @click="
        router.push({
          path: `/${Route.ORG_STRUCTURE_TEMPLATES}`,
          query: {
            tab: OrgTemplatesTab.ORG_STRUCTURES,
            search: '',
          },
        })
      "
    >
      Завершить редактирование
    </app-button>
  </div>
  <logout-confirm-dialog v-model="isLogoutConfirmVisible" />
</template>

<script lang="ts" setup>
import AppSelect from "@/shared/UIKit/app-select/app-select.vue";
import { useProjectsStore } from "@/stores/projects/projects";
import AppButton from "@/shared/UIKit/app-button/app-button.vue";
import { useRoute, useRouter } from "vue-router";
import { SidebarType } from "@/layouts/sidebars/sidebars.types";
import { Route } from "@/router/router.types";
import { computed, defineModel, ref } from "vue";
import type { IOption } from "@/shared/UIKit/app-select/app-select.types";
import AppPopover1 from "@/shared/UIKit/app-popover-1.vue";
import LogoutConfirmDialog from "@/modules/user-cabinet/views/profile/components/dialogs/logout-confirm-dialog.vue";
import { useUserStore } from "@/stores/user/user";
import { useBalanceDialogStore } from "@/stores/dialogs/balance-dialog";
import { OrgTemplatesTab } from "@/views/org-structure-templates/data";

const model = defineModel<boolean>({ required: true });
const route = useRoute();
const router = useRouter();
const projectsStore = useProjectsStore();
projectsStore.getProjects();
const isLogoutConfirmVisible = ref(false);
const userStore = useUserStore();
const balanceDialogStore = useBalanceDialogStore();

const emit = defineEmits<{
  "add-project": [];
}>();

const projectsOptions = computed((): IOption[] => {
  return projectsStore.projects.map((item) => ({
    ...item,
    title: item.name,
    value: item.id,
  }));
});

const handleProjectChange = () => {
  localStorage.setItem("project-id", projectsStore.selectedProjectId);
};

const handleLogOut = (close: () => void) => {
  close();
  isLogoutConfirmVisible.value = true;
};

const shouldShowProjects = computed(() => {
  return (
    projectsStore.projects.length &&
    route.name !== Route.ADMIN_PROFILE &&
    route.name !== Route.USER_PROFILE
  );
});

const userName = computed(() => {
  if (!userStore.user) {
    return "";
  }

  return userStore.user.firstName
    ? `${userStore.user.secondName} ${userStore.user.firstName} ${userStore.user.middleName}`
    : "";
});
</script>

<style lang="scss" scoped>
::v-deep(.app-popover-new) {
  #arrow {
    --popper-theme-background-color: #ffffff;
    --popper-theme-background-color-hover: #ffffff;
    --popper-theme-border-width: 1px;
    --popper-theme-border-style: solid;
    --popper-theme-border-color: #e8e8e9;
    top: -4px !important;
  }
}
</style>

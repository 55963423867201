import axiosInstance from "./api-instance";
import { BusinessProcessesAPI } from "@boostbiz/business_processes";
import { AuthAPI } from "@boostbiz/auth";
import { TestingAPI } from "@boostbiz/testing_form_microservice";
import { QuestionFormAPI as SurveyAPI } from "@boostbiz/question_form_microservice";
import { BPChatAPI } from "@boostbiz/bp_chat";
import { ProjectAPI } from "@boostbiz/project_service";
import { OrgStructureAPI } from "@boostbiz/org_client";
import { WikiAPIClient } from "@boostbiz/wiki_client";
import { BoostAIAPI } from "@boostbiz/boost_ai";
import { PaymentAPIClient } from "@boostbiz/payment_client";

export const paymentApi = new PaymentAPIClient(axiosInstance);
export const wikiApi = new WikiAPIClient(axiosInstance);
export const gptApi = new BoostAIAPI(axiosInstance);
export const orgStructureApi = new OrgStructureAPI(axiosInstance);
export const projectsApi = new ProjectAPI(axiosInstance);
export const businessProcessesApi = new BusinessProcessesAPI(axiosInstance);
export const authApi = new AuthAPI(axiosInstance);
export const testingApi = new TestingAPI(axiosInstance);
export const surveyApi = new SurveyAPI(axiosInstance);
export const bpChatApi = new BPChatAPI(axiosInstance);

<template>
  <navigation-layout
    :class="{ '!translate-x-0': isSidebarOpened }"
    :level="2"
    class="translate-x-full duration-200"
    is-sub-layout
    title="Новый опрос"
  >
    <template #header>
      <app-button size="small" @click="handleBackClick" type="texted" variant="tertiary" icon class="absolute left-2.5 top-1/2 -translate-y-1/2 w-10 !p-0">
        <img
          alt=""
          class="img--hoar !w-5 !h-5"
          src="@/assets/icons/direction/arrow-right-long.svg"
        />
      </app-button>
    </template>

    <template #default>
      <div class="m-1.5">
        <settings-questions-tabs />
      </div>
      <div class="grid grid-cols-3 gap-1.5 border-y border-y-lunar p-1.5">
        <action-button
          v-for="action in CREATE_ACTIONS"
          :key="action.value"
          :action="action"
          :active="action.value === CreateAction.CREATE"
          :disabled="isPreviewLoading || isDraftLoading || isCreateLoading"
          :loading="resolveCurrentActionLoading(action.value as CreateAction)"
          @click="resolveCurrentAction(action.value as CreateAction)"
        />
      </div>
    </template>
  </navigation-layout>
</template>
<script lang="ts" setup>
import { computed, ref } from "vue";
import NavigationLayout from "@/layouts/navigation/navigation-layout.vue";
import { useSidebarStore } from "@/stores/sidebar/sidebar";
import { storeToRefs } from "pinia";
import { SidebarType } from "@/layouts/sidebars/sidebars.types";
import SettingsQuestionsTabs from "@/layouts/sidebars/knowledge-base-create-sidebar/settings-questions-tabs.vue";
import {
  CREATE_ACTIONS,
  CreateAction,
} from "@/layouts/sidebars/knowledge-base-create-sidebar/knowledge-testing-survey-sidebar.data";
import ActionButton from "@/modules/knowledge-base/views/testing-survey/components/action-button.vue";
import { useKnowledgeBaseStore } from "@/stores/knowledge-base/knowledge-base";

import { surveyApi } from "@/api";
import { useSnackbarStore } from "@/stores/snackbar/snackbar";
import { Route } from "@/router/router.types";
import { useRouter } from "vue-router";
import type { QuestionFormDTO } from "@boostbiz/question_form_microservice/client/schemas";
import AppButton from "@/shared/UIKit/app-button/app-button.vue";
import { TestingTab } from "@/modules/knowledge-base/views/testing-survey/testing/tabs/testing-tabs.data";

const router = useRouter();

const { showSnackbar } = useSnackbarStore();

const sidebarStore = useSidebarStore();
const knowledgeBaseStore = useKnowledgeBaseStore();

const { currentSidebars } = storeToRefs(sidebarStore);

const isSidebarOpened = computed(
  () =>
    currentSidebars.value[currentSidebars.value.length - 1] ===
    SidebarType.KNOWLEDGE_BASE_SURVEY_CREATE,
);

const handleBackClick = () => {
  const confirmed = window.confirm("Вы уверены, что хотите завершить создание?\nВсе изменения будут утеряны.");

  if (confirmed) {
    sidebarStore.goBack();
    router.push({
      path: `/${Route.KNOWLEDGE_BASE_SURVEY}`,
      query: {
        page: 1,
        count: 10,
        search: "",
      },
    })
  }
};

const isCreateLoading = ref(false);
const isPreviewLoading = ref(false);
const isDraftLoading = ref(false);

const resolveCurrentActionLoading = (action: CreateAction) => {
  switch (action) {
    case CreateAction.CREATE:
      return isCreateLoading.value;
    case CreateAction.DRAFT:
      return isDraftLoading.value;
    case CreateAction.PREVIEW:
      return isPreviewLoading.value;
  }
};

const createSurvey = async () => {
  if (
    knowledgeBaseStore.surveyFormRef!.validate() &&
    knowledgeBaseStore.surveyQuestionsRef!.validate()
  ) {
    isCreateLoading.value = true;

    try {
      const surveyId = await surveyApi.actions.createQuestionForm(
        knowledgeBaseStore.survey,
      );

      await surveyApi.actions.startByOwner(surveyId);

      showSnackbar("Опрос успешно создан и запущен");

      await router.push({
        path: `/${Route.KNOWLEDGE_BASE_SURVEY}`,
        query: {
          page: 1,
          count: 10,
          search: "",
        },
      })
    } catch (e) {
      showSnackbar(e?.response?.data?.message);
    } finally {
      isCreateLoading.value = false;
    }
  }
};

const sendToDraftSurvey = async () => {
  isDraftLoading.value = true;

  try {
    await surveyApi.actions.createQuestionForm(knowledgeBaseStore.survey);

    showSnackbar("Опрос успешно сохранен в черновик");
  } catch (e) {
    showSnackbar(e?.response?.data?.message);
  } finally {
    isDraftLoading.value = false;
  }
};

const previewSurvey = async () => {
  isPreviewLoading.value = true;
  knowledgeBaseStore.surveyPreview =
    knowledgeBaseStore.survey as unknown as QuestionFormDTO;
  await router.push(`/${Route.KNOWLEDGE_BASE_SURVEY_PREVIEW}`);
  isPreviewLoading.value = false;
};

const resolveCurrentAction = (action: CreateAction) => {
  switch (action) {
    case CreateAction.CREATE:
      return createSurvey();
    case CreateAction.DRAFT:
      return sendToDraftSurvey();
    case CreateAction.PREVIEW:
      return previewSurvey();
  }
};
</script>

<style lang="scss" scoped></style>

<template>
  <div>
    <div
      :class="[
        `app-input-container--${size}`,
        {
          'app-input-container--error': errorMessage || error,
          'app-input-container--disabled': disabled,
          'app-input-container--readonly': readonly,
        },
      ]"
      class="app-input-container"
    >
      <slot name="prepend" />
      <input
        ref="inputRef"
        v-model.trim="model"
        :class="[
          {
            'floating-input': label,
            '!text-primary':
              String(model).startsWith('@') && !readonly && !disabled,
          },
        ]"
        :maxlength="maxLength"
        :name="name"
        :placeholder="placeholder"
        :type="type"
        autocomplete="off"
        class="app-input-container__input"
        @blur="handleInputBlur"
        @input="handleInputChange"
        @keypress="handleInputKeypress"
        @keydown.space.stop
      />
      <label v-if="label" class="floating-label">{{ label }}</label>
      <slot name="append" />
      <!--      <span-->
      <!--        v-if="errorMessage"-->
      <!--        class="absolute -bottom-5 left-[6px] text-11 font-medium text-tomato"-->
      <!--        >{{ errorMessage }}</span-->
      <!--      >-->
    </div>
    <p
      v-if="errorMessage && errorMessage.length"
      class="mt-0.5 pl-[6px] text-11 font-medium text-tomato"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from "vue";
import IMask from "imask";
import { useField } from "vee-validate";
import { PHONE_FULL_NUMBER_COUNT } from "@/modules/user/user.utils";
import dayjs from "dayjs";

const MIN_YEAR = 1900;

interface Props {
  placeholder?: string;
  maxLength?: number | string;
  max?: number;
  min?: number;
  defaultValue?: string | number;
  name?: string;
  disabled?: boolean;
  type?: "text" | "password" | "phone" | "number" | "date";
  mask?: "date";
  size?: "base" | "small";
  error?: boolean;
  label?: string;
  readonly?: boolean;
  modelValue?: string | number;
  validateOnBlur?: boolean;
  validateOnUpdate?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  type: "text",
  size: "base",
  label: "",
  placeholder: "",
  defaultValue: "",
  name: "",
  error: false,
  validateOnBlur: false,
  validateOnUpdate: false,
});

const emit = defineEmits<{
  blur: [];
  "update:modelValue": [val: string];
  input: [value: string];
}>();

const inputType = ref(props.type);
const inputRef = ref();
const {
  meta,
  handleBlur,
  handleChange,
  errorMessage,
  value: model,
} = useField(() => props.name, undefined, {
  syncVModel: true,
  validateOnValueUpdate: props.validateOnUpdate,
});

const maskInput = (event: Event) => {
  const input = event.target as HTMLInputElement;
  let maskOptions;
  if (props.type === "phone") {
    maskOptions = {
      mask: "0 (000) 000-00-00",
    };
  }

  if (props.mask === "date") {
    maskOptions = {
      mask: Date,
      blocks: {
        d: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 31,
          maxLength: 2,
        },
        m: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 12,
          maxLength: 2,
        },
        Y: {
          mask: IMask.MaskedRange,
          from: MIN_YEAR,
          to: dayjs().get("year"),
        },
      },
    };
  }
  if (maskOptions) {
    IMask(input, maskOptions);
  }
};

const handleInputBlur = (evt) => {
  if (props.defaultValue) {
    model.value = evt.target?.value
      ? evt.target?.value.padStart(2, "0")
      : props.defaultValue;
  }

  handleBlur(evt, props.validateOnBlur);
};

const handleInputKeypress = (evt) => {
  if (props.min || props.max || props.type === "number") {
    const keysAllowed: string[] = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
    ];
    const keyPressed: string = evt.key;

    if (!keysAllowed.includes(keyPressed)) {
      evt.preventDefault();
    }
  }
};

const handleInputChange = (evt) => {
  maskInput(evt);
  emit("input", evt.target.value);
  handleChange(evt, meta.touched);

  if (props.min || props.max || props.type === "number") {
    const value = Number(evt.target?.value);

    if (props.max && value > props.max) {
      model.value = props.max;
      inputRef.value.value = props.max;

      return;
    }

    if (props.min && value < props.min) {
      model.value = props.min;
      inputRef.value.value = props.min;

      return;
    }
    model.value = Number(evt.target?.value) || 0;
  } else {
    model.value = evt.target?.value || "";
  }
};

watch(
  () => props.min,
  () => {
    const valueToChange = Math.max(inputRef.value.value, props.min);
    model.value = valueToChange;
    inputRef.value.value = valueToChange;
  },
);

watch(
  () => props.max,
  () => {
    const valueToChange = Math.min(inputRef.value.value, props.max);
    model.value = valueToChange;
    inputRef.value.value = valueToChange;
  },
);

watch(
  () => model.value,
  (input: string) => {
    if (props.type === "phone") {
      model.value = input.substring(0, PHONE_FULL_NUMBER_COUNT);
    }
  },
  {
    immediate: true,
  },
);

defineExpose({ inputRef });
</script>

<style lang="scss" scoped>
.app-input-container {
  @apply relative flex h-[42px] w-full items-center justify-between gap-3 rounded-[10px] border border-grizzle bg-white px-4 py-[2px] duration-200 dark:border-hoar/15 dark:bg-hoar/5 dark:hover:bg-transparent;

  &:focus-within {
    @apply border-primary bg-white dark:bg-transparent;
  }

  &:has(input:not(:placeholder-shown)) {
    @apply bg-white;
  }

  &:has(input:not(:placeholder-shown)):not(:focus-within) {
    @apply border-grizzle;
  }

  &--error {
    @apply border-tomato #{!important};

    .floating-label {
      @apply text-tomato #{!important};
    }
  }

  &--small {
    @apply h-9 px-3 #{!important};

    .floating-label {
      @apply left-3 text-13 #{!important};
    }

    &:has(input:focus) {
      .floating-label {
        @apply left-4 -translate-y-[18px] text-11 #{!important};
      }
    }

    input {
      @apply text-13 #{!important};

      &::placeholder {
        @apply text-13 #{!important};
      }
    }

    &:has(input:not(:placeholder-shown)) {
      .floating-label {
        @apply left-4 -translate-y-[18px] text-11  #{!important};
      }
    }
  }

  &--readonly {
    @apply pointer-events-none;
  }

  &--disabled {
    @apply pointer-events-none border-grizzle/[0.70];

    &:has(input:placeholder-shown) {
      @apply bg-background #{!important};
    }

    &:has(input:not(:placeholder-shown)) {
      @apply bg-white;

      input {
        @apply text-hoar;
      }
    }
  }

  &:hover:not(:focus-within):has(input:placeholder-shown) {
    @apply border-smoke dark:border-primary;
  }

  &__input {
    @apply h-full w-full text-ellipsis bg-transparent text-14 font-medium dark:text-white  #{!important};
  }

  // floating

  .floating-label {
    @apply pointer-events-none absolute left-4 z-[5] flex h-5 w-min items-center justify-center text-nowrap text-14 font-medium text-hoar transition-all duration-300 dark:opacity-50;
  }

  &:has(input:focus) {
    .floating-label {
      @apply -translate-x-1 -translate-y-full bg-white px-1 text-11 font-medium text-primary;
    }

    .app-input-container {
      @apply bg-white;
    }
  }

  &:has(input:not(:placeholder-shown)) {
    .floating-label {
      @apply -translate-x-1 -translate-y-full bg-white px-1 text-11 font-medium;
    }
  }
}
</style>

<template>
  <Popper
    :class="{ 'pointer-events-none': disabled }"
    :offsetDistance="offset"
    :placement="placement"
    :onOpen:popper="() => handlePopupOpen(true)"
    :onClose:popper="() => handlePopupOpen(false)"
    class="app-popover-new flex items-center justify-center"
    offsetSkid="0"
    :arrow="arrow"
    v-bind="$attrs"
  >
    <slot />
    <template #content="props">
      <slot name="content" v-bind="props" />
    </template>
  </Popper>
</template>

<script lang="ts" setup>
import Popper from "vue3-popper";
import type { Placement } from "@floating-ui/vue";

interface Props {
  placement?: Placement;
  offset?: string;
  disabled?: boolean;
  arrow?: boolean;
}
const handlePopupOpen = (val: boolean) => {
  emit("popupOpened", val)
}

const props = withDefaults(defineProps<Props>(), {
  placement: "right",
  offset: "12",
  disabled: false,
  arrow: false,
});

const model = defineModel<boolean>({ default: false });

const emit = defineEmits<{
  popupOpened: [value: boolean];
}>()
</script>
<style lang="scss">
.app-popover-new {
  div:not([class]) {
    @apply flex items-center;
  }

  & > .popper {
    background: transparent !important;
    padding: unset !important;
    color: unset !important;
    border-radius: unset !important;
    box-shadow: unset !important;
    border: unset !important;
  }
}

</style>
